import { ref } from 'vue'

export const isOpenPopover = ref(false)
export const showDetail = ref(-1)
export const expandFab = ref(false)

export function onClickMenu () {
  isOpenPopover.value = !isOpenPopover.value
}

export function onCloseMenu () {
  isOpenPopover.value = false
}

export function onClickContent () {
  if (isOpenPopover.value || showDetail.value > -1 || expandFab.value) {
    onCloseMenu()
    showDetail.value = -1
    expandFab.value = false
  }
}