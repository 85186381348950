import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'
import { createMetaManager, defaultConfig } from 'vue-meta'

/* Theme variables */
import './theme/variables.scss'

/* analytics */
import VueGtag from 'vue-gtag-next'

/* splide */
import VueSplide from '@splidejs/vue-splide'

import { abtest, initScreenInfo } from '@/utils'

/* ABTest */
abtest.initTest()

/* platform state */
initScreenInfo()

import { registerIcons } from './icons'
registerIcons()

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(VueSplide)
  .use(createMetaManager(false, {
    ...defaultConfig,
    keywords: {
      tag: 'meta',
    },
    author: {
      tag: 'meta',
    },
    meta: {
      tag: 'meta',
      nameless: true,
    },
  }))
  .use(VueGtag, {
    property: {
      id: 'G-42EVXSRH38',
    },
  })
  
router.isReady().then(() => {
  app.mount('#app')
})