import { ref, Ref } from 'vue'
import { MovingInfo } from '@/types'

export const screenWidth: Ref<number> = ref(innerWidth)
export const screenHeight: Ref<number> = ref(innerHeight)
export const scrollTop: Ref<number> = ref(0)

export function initScreenInfo () {
  window.addEventListener('resize', _onScreenChange)
}

function _onScreenChange () {
  screenWidth.value = innerWidth
  screenHeight.value = innerHeight
}

export class MoveElementPosition {
  pos: MovingInfo

  constructor (movingInfo: MovingInfo) {
    this.pos = movingInfo
  }

  toStyle (x: number, y?: number) {
    if (screenWidth.value > 768) {
      return !y ? `left: min(${x}px, 146px);` : `top: min(${y}px, 29px); left: min( ${x}px, 464px);` 
    } else {
      return !y ? `left: min(${x}px, 149px);` : `top: min(${y}px, 220px); left: min( ${x}px, 200px);` 
    }
  }

  getElementPosition (needY = true) {
    let curX1 = 0, curY1 = 0
    curX1 = ((screenWidth.value - this.pos.endScreenWidth) / (this.pos.startScreenWidth - this.pos.endScreenWidth)) * (this.pos.startPosX - this.pos.endPosX) + this.pos.endPosX
    curY1 = ((screenWidth.value - this.pos.endScreenWidth) / (this.pos.startScreenWidth - this.pos.endScreenWidth)) * (this.pos.startPosY - this.pos.endPosY) + this.pos.endPosY
    return this.toStyle(curX1, needY ? curY1 : undefined)
  }
}