import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'Home',
    path: '/',
    component: () => import(/* webpackChunkName: "Home" */'../views/Home.vue'),
  },
  {
    name: 'Giganje',
    path: '/substitute-teacher',
    component: () => import(/* webpackChunkName: "Giganje" */'../views/Giganje.vue'),
  },
  {
    name: 'Boyuk',
    path: '/nursery-teacher',
    component: () => import(/* webpackChunkName: "Boyuk" */'../views/Boyuk.vue'),
  },
  {
    name: 'TermOfService',
    path: '/term-of-service',
    component: () => import(/* webpackChunkName: "TermOfService" */'../views/TermOfService.vue'),
  },
  {
    name: 'PrivacyPolicy',
    path: '/privacy-policy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */'../views/PrivacyPolicy.vue'),
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'not-found', 
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router