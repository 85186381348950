class AbTest {
  date = ''

  getUserType () {
    return parseInt(this.date) % 2
  }

  initTest () {
    const date = this.loadDate()
    if (date) {
      this.date = date
    } else {
      const newDate = Date.now().toString()
      this.date = newDate
      this.saveDate(newDate)
    }
  }

  saveDate (date: string) {
    window.localStorage.setItem('date', date)
  }

  loadDate () {
    return window.localStorage.getItem('date')
  }
}

export const abtest = new AbTest()